<template>
  <div class="header-background"></div>
</template>
<script>
export default {

};

</script>
<style lang="scss">
.header-background {
  background-color: var(--twoColor);
  height: 116px;
}
@media (max-width: 768px) {
  .header-background
  {
    height: 80px;
  }
}
@media (max-width: 540px) {
  .header-background
  {
    height: 58px;
  }
}
</style>
