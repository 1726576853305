import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    language: 'rus',
    URL__DATA: 'https://api.sudural.ru/',
    receivedData: {},
    loading: true,

    allContent: {
      main: {
        imgText: {
          rus: '“Защищая высшие ценности — человека,<br/> его права и свободы — мы защищаем<br/> саму жизнь”',
          eng: '“By protecting the highest values of man, his rights and freedoms, we protect life itself”',
        },
        headerArticles: {
          rus: 'Объявления',
          eng: 'Advertisements',
        },
        advantages: [
          {
            id: 1,
            header: {
              rus: 'Профессионализм',
              eng: 'Professionalism',
            },
            rus: 'Наш практический опыт и знания позволяют достигать поставленных целей',
            eng: 'Our practical experience and knowledge allow us to achieve our goals',
            img: 'Themis.png',
          },
          {
            id: 2,
            header: {
              rus: 'Надежность',
              eng: 'Reliability',
            },
            rus: 'Действуем в соответствии с Федеральным законом «Об адвокатской деятельности и адвокатуре в РФ», руководствуемся Кодексом профессиональной этики адвокатов',
            eng: 'We act in accordance with the Federal Law "On Advocacy and Advocacy in the Russian Federation", guided by the Code of Professional Ethics of Lawyers',
            img: 'gavel.png',
          },
          {
            id: 3,
            header: {
              rus: 'Честность',
              eng: 'Honesty',
            },
            rus: 'Нам доверяют, потому что мы не вводим Вас в заблуждение и не даём безосновательных надежд',
            eng: 'They trust us because we do not mislead you and do not give you unfounded hopes',
            img: 'scales.png',
          },
        ],
        bottomText: {
          rus: 'Наша квалифицированная команда разработает и реализует Меморандум тактики и стратегии эффективной защиты прав, свобод и законных интересов для разрешения вашей сложной задачи',
          eng: 'Our qualified team will develop and implement a Memorandum of tactics and strategies for the effective protection of rights, freedoms and legitimate interests to solve your difficult task',
        },
      },
      lawyer: {
        rus: 'Адвокат',
        eng: 'Lawyer',
      },
      header: {
        main: {
          rus: 'Главная',
          eng: 'Main',
        },
        ScientificAndLegalCenter: {
          rus: 'Научно-Правовой центр',
          eng: 'Scientific and Legal Center',
        },
        Events: {
          rus: 'Мероприятия',
          eng: 'Events',
        },
      },
      EventsPage: {
        header: {
          rus: 'Мероприятия',
          eng: 'Events',
        },
        moreDetailed: {
          rus: 'Подробнее',
          eng: 'More detailed',
        },
        slideHelper: {
          rus: 'Нажмите чтобы ознакомиться с презентацией',
          eng: 'Click to view the presentation',
        },
      },
      contact: {
        header: {
          rus: 'Как можно связаться со мной',
          eng: 'How can you contact me',
        },
        numberPhone: {
          rus: 'Номер телефона',
          eng: 'Phone number',
        },
        email: {
          rus: 'Почта',
          eng: 'Email',
        },
        address: {
          rus: 'Адрес',
          eng: 'Address',
        },
        pdf: {
          rus: 'Подробнее ознакомиться с материалом вы можете по ссылке',
          eng: 'To learn more about the material, follow the link',
        },
      },
      EducationalPublications: {
        rudich: {
          text: {
            rus: `
        Список опубликованных учебных изданий
         и научных трудов доктора юридических наук,
          адвоката Рудича Валерия Владимировича
        `,
            eng: `List of published educational publications
        and scientific works of the Doctor of Law,
        lawyer Rudich Valery Vladimirovich`,
          },
          textLink: {
            rus: 'Ознакомиться со статьями:',
            eng: 'Read the articles:',
          },
          button: {
            rus: 'Открыть PDF файл',
            eng: 'Open PDF file',
          },
          link: '',
        },
        abramov: {
          text: {
            rus: `
        Список опубликованных научных трудов
         кандидата юридических наук,
          адвоката Абрамова Александра Борисовича
        `,
            eng: `List of published educational publications
        and scientific works of the Doctor of Law,
        lawyer Rudich Valery Vladimirovich`,
          },
          textLink: {
            rus: 'Ознакомиться со статьями:',
            eng: 'Read the articles:',
          },
          button: {
            rus: 'Открыть PDF файл',
            eng: 'Open PDF file',
          },
          link: '',
        },
      },
    },
  },
  getters: {
  },
  mutations: {
    updateData(state, data) {
      state.receivedData = data;
    },
    loadingUpdate(state, bool) {
      state.loading = bool;
    },
    languageUpdate(state, lang) {
      state.language = lang;
    },
    addEvent(state) {
      state.lawyerEvents.push({
        cardHeader: {
          rus: '',
          eng: '',
        },
        img: '',
        cardText: {
          rus: '',
          eng: '',
        },
        content: {
          rus: '',
          eng: '',
        },
        id: 'newEvent',
        pdf: [],
      });
    },
  },
  actions: {
    async GetData(context) {
      axios.get(`${this.state.URL__DATA}api/get`)
        .then((response) => {
          context.commit('updateData', response.data.pages);
          context.commit('loadingUpdate', false);
        });
    },
  },
  modules: {},
});
