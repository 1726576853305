<template>
<HeaderSlot/>
    <section class="LegalCenter">
        <div class="container">
            <HeaderItem>
            <h1 class="legal-center__header"
             v-html="$store.state.receivedData.LegalCenter.header[$store.state.language]">

            </h1>
            </HeaderItem>
            <p class="legal-center__text"
             v-html="$store.state.receivedData.LegalCenter.text[$store.state.language]">

            </p>
        </div>
    </section>
</template>
<script>
import HeaderSlot from '@/components/HeaderSlot.vue';

export default {
  components: { HeaderSlot },
};
</script>
<style lang="scss" >
.LegalCenter{
    background: rgb(96,114,131);
background: linear-gradient(180deg, rgba(96,114,131,1) 0%, rgba(55,69,80,1) 100%);
padding-bottom: 70px;
}
.legal-center__text
{
    margin-top: 100px;
    font-size: 32px;
    margin-bottom: 0px;
}
@media (max-width: 768px) {
.legal-center__text
{
    font-size: calc(var(--index) * 1.5) !important;
    margin-top: 40px;
}

}
</style>
