<template>
    <li class="main-card__advantages">
        <img :src="require(`../assets/img/${advantage.img}`)" alt="" class="main-card__img">
        <div class="main-card__header-text">
        <div class="main-card__header">
          {{ advantage.header[$store.state.language] }}
        </div>
        <div class="main-card__advantages__text">
          {{ advantage[$store.state.language] }}
      </div>
    </div>
    </li>
</template>
<script >

export default {
  props: ['advantage'],

};
</script >
<style lang="css" scoped>
.main-card__advantages
{
    width: clamp( 120px,20vw, 255px);
    height: 298px;
    padding: 10px 8px 0px 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(3px);
    background: rgb(185,199,209);
background: linear-gradient(180deg, rgba(185, 199, 209, 0.61) 0%, rgba(66, 82, 96, 0.63) 100%);

}
.main-card__img {
  height: clamp( 40px, 6vw, 90px);
  margin-bottom: 0px;
}
.main-card__header-text
{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-card__header
{
  color: var(--fontColor);
  font-size: clamp(15px, 2vw , 25px);
  margin-bottom: 5px;
  font-family: 'El Messiri';
}
.main-card__advantages__text {
  color: var(--fontColor);
  text-align: center;
  font-size: clamp(12px, 1.8nvw , 20px);
  line-height: 125%;
  font-family: 'Cormorant';
  font-weight: 500;
  line-height: 1;
}
@media (max-width:768px) {
  .main-card__advantages
{
    width: 100%;
    height: auto;
    margin: 20px 0px;
    padding-bottom: 20px;
    flex-direction: row-reverse;
    justify-content: space-between;

}
.main-card__header{
}
.main-card__img {
  height: auto;
  width: 25%;
  margin-left: 5px;
}
.main-card__advantages__text {
  color: var(--fontColor);
  text-align: center;
}
}
</style>
