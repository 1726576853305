<!-- eslint-disable max-len -->
<template>
  <HeaderSlot></HeaderSlot>
    <div class="main__top__img-and-text">
      <img :src="mobil ? require('../assets/img/mobileHome.jpg') :require('../assets/img/gavelHome1.jpg')" alt="" class="main__top__img">
      <p class="main__top__text" v-html="$store.state.allContent.main.imgText[$store.state.language] "></p>
    </div>
    <div class="container">
      <ul class="main__cards">
        <MainCard v-for="advantage in $store.state.allContent.main.advantages" :key="advantage.id" :advantage="advantage"></MainCard>
      </ul>
    </div>
  <ArticlesMain/>
  <MainBottom></MainBottom>
</template>
<script>
import ArticlesMain from '@/components/ArticlesMain.vue';
import HeaderSlot from '@/components/HeaderSlot.vue';
import MainCard from '@/components/MainCard.vue';
import MainBottom from '@/components/MainBottom.vue';

export default {
  components: {
    ArticlesMain, MainCard, HeaderSlot, MainBottom,
  },
  data() {
    return {
      mobil: false,

    };
  },
  methods: {
  },
  beforeCreate() {
  // adding title for current view/page using vue-i18n
    const title = document.querySelector('title');
    title.innerText = 'SUDURAL Рудич В.В. и Абрамов А.Б.';

    const description = document.createElement('META');
    description.setAttribute('name', 'description');
    description.setAttribute('content', 'Адвокаты Рудич В.В. и Абрамов А.Б. предоставляют высококачественные юридические услуги. Мы специализируемся на уголовных, гражданских и административных делах, обеспечивая полную конфиденциальность и защиту интересов клиента. С нашей помощью, вы можете быть уверены в успешном исходе вашего дела.');
    document.querySelector('head').appendChild(description);
  },
  created() {
    if (window.innerWidth < 500) {
      this.mobil = true;
    } else {
      this.mobil = false;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 500) {
        this.mobil = true;
      } else {
        this.mobil = false;
      }
    });
  },
};
</script>
<style>
  @font-face {
    font-family: 'El Messiri';
    src: url('../assets/fonts/ElMessiri-VariableFont_wght.ttf');
  }
  .main__top__img-and-text
  {
    width: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    max-height: 75vh;
    overflow: hidden;
    position: relative;
  }
  .main__top__img
  {
    width: 100%;
  }
  .main__top__text
  {
    font-size: clamp(12px,2.5vw, 50px);
    position: absolute;
    letter-spacing: clamp(1px,0.6vw, 10px);
    top: 23%;
    vertical-align: center;
  left: 0;
  right: 0;
  padding: 0px 10%;
    color: white;
    font-family: 'Cormorant';
    font-weight: 700;
  }
  .main__cards
  {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: stretch;
    position: relative;
    top: -180px;
    padding: 0px;
    height: 150px;
  }
  @media (max-width: 1024px) {
  }
  @media (max-width:768px) {
    .main__cards
    {
      margin-top: 50px;
      display: block;
      position: static;
      width: 100%;
    }
    .main__top__text{
      letter-spacing: 0.2rem;
      padding: 5px;
      text-align: start;
      font-size: 30px !important;
    }
    .main__cards{
    height: auto;
  }
  }
  @media (max-width: 500px) {
    .main__top__text
  {
    font-size: 25px;
  }
  }
</style>
