<template>
  <section class="main-bottom">

      <div class="main-bottom__container">
        <div class="main-bottom__img">
        </div>
        <div class="main-bottom__text">
          {{ $store.state.allContent.main.bottomText[$store.state.language] }}
        </div>
      </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
.main-bottom
{
    margin-top: 300px;
    margin-bottom: 74px;
}
.main-bottom__container
{
    max-width: 1350px;
    margin: auto;

    position: relative;
    padding: 20px;
    border-radius: calc(var(--index)*12);
box-shadow: 5px -8px 22.6px 0px rgba(0, 0, 0, 0.25);
background: rgb(16,44,66);
background: linear-gradient(180deg, rgba(16, 44, 66, 0) 0%, rgba(255, 255, 255, 0.397) 100%);
}
.main-bottom__img
{
    background-image: url('../assets/img/ThemisBottom.png');
    width: 100%;
    height: 160%;
    background-repeat: no-repeat;
    background-position: 0px 20px;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 0;
}
.main-bottom__text
{
    width: 55%;
    margin-left: auto;
    margin-right: 50px;
    padding: 50px;
    border-radius:  calc(var(--index)*3);
    position: relative;
    top: -80px;
    color: var(--fontColor);
    font-size: clamp(18px,2vw,38px);
    z-index: 20;
    font-family: 'Cormorant';
    background: rgb(151,176,196);
background: linear-gradient(180deg, rgba(151, 176, 196, 0.4) 0%, rgba(73,91,105,0.4) 100%);

}
@media (max-width: 768px) {
    .main-bottom__text{
        top: 0;
        height: 100%;
        width: 100%;
        display: block;
    }
    .main-bottom__container
    {
        margin: 0px;
        padding: 0px;
        display: block;
        background: transparent;
        box-shadow: none;
        padding: 0px 5px;
    }
    .main-bottom__img{
        position: static;
        height: 36vh;
    }
    .main-bottom
    {
        margin-top: 100px;
    }
}
</style>
